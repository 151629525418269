<template>
  <b-modal
    id="create-user-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title> <h2 class="m-0">Create User</h2> </template>
    <validation-observer ref="createUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="username">
              <template #label>
                Username
                <span class="text-danger">* </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Username"
                :rules="{ required, regex: /^[A-Z0-9]*$/i }"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Username"
                  @keydown="checkMaxLength($event, 'username', 100)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="password">
              <template #label>
                Password <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Password"
                :rules="{
                  required: true,
                  regex:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="fullName">
              <template #label>
                Full Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Full Name"
                :rules="{ required }"
              >
                <b-form-input
                  id="fullName"
                  v-model="fullName"
                  v-restrict="regex"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Full Name"
                  @keydown="checkMaxLength($event, 'full_name', 200)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label-for="cnic">
              <template #label>
                CNIC 
              </template>
              <validation-provider
                #default="{ errors }"
                name="CNIC"
                rules="integer|length:13"
              >
                <b-form-input
                  id="cnic"
                  v-model="cnic"
                  :state="errors.length > 0 ? false : null"
                  placeholder="CNIC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group label-for="mobile">
              <template #label>
                Mobile <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                rules="required|integer|length:11"
              >
                <b-form-input
                  id="mobile"
                  v-model="mobile"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="email">
              <template #label>
                Email <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  @keydown="checkMaxLength($event, 'email', 100)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <VueSelectPaginated
              placeholder="Role"
              name="Role"
              label="name"
              rules="required"
              searchBy="name"
              :getListMethod="getRoles"
              @setMethod="
                (value) => {
                  role = value;
                }
              "
            />
          </b-col>
        </b-form-row>
        <p class="font-weight-bolder text-danger">
          NOTE:
          <span class="font-weight-bold text-dark"
            >The password must be at least 8 characters long, at least one
            lowercase letter, at least one uppercase letter, at least one digit
            and at least one special character (e.g., @$!%*?&)</span
          >
        </p>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button type="submit" variant="primary" pill @click="validationForm">
          Submit
        </b-button>
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],
  data() {
    return {
      required,
      username: "",
      password: "",
      fullName: "",
      // lastName: "",
      cnic: "",
      mobile: "",
      email: "",
      role: null,
      regex: /^[a-zA-Z ]*$/,
    };
  },
  methods: {
    ...mapActions({
      createUser: "appData/createUser",
      getRoles: "appData/getRoles",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.createUserFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.createUser({
          username: this.username,
          password: this.password,
          full_name: this.fullName,
          // last_name: this.lastName,
          cnic: this.cnic ? cnic : null,
          mobile: this.mobile,
          email: this.email,
          role: this.role.id,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
        });
        if (res.status === 201) {
          this.$swal({
            title: "User created successfully",
            icon: "success",
          });
          this.$nextTick(() => {
            this.$bvModal.hide("create-user-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style></style>
